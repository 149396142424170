<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">科学仪器为您揭开古玩艺术品的真伪！</div>
      <p class="time">2018.03.22</p>
      <p class="space_content">
        海峡导报报道：
      </p>
      <p class="space_content">
        导报讯（记者 吴舒远）2018年3月21日下午，由
        <span style="font-weight: 600;font-size: 16px">
          海峡导报、福建省古玩商会厦门分会、
        </span>
        <span style="font-weight: 600;font-size: 16px;color: red">
          北京垣钧文物鉴定技术研究院
        </span>
        联合举办的鉴宝艺术沙龙在厦门顺天达拍卖有限公司举行。参加本次活动的有来自古玩界的藏友们和导报读者三十余名。
      </p>
      <p class="space_content">
        活动现场，首先由福建省古玩商会厦门分会会长吴顺强分享了对古玩艺术品市场的现状及未来市场趋势的剖析。吴顺强会长以他二十几年收藏的经验，用其言简意赅且生动有趣的表达方式讲解了古玩的概念，剖析了艺术品在精神层面给人带来的益处等等。另外，记者首次在现场看到由
        <span style="font-weight: 600;font-size: 16px;color: red">
          北京垣钧文物鉴定技术研究院院长曾志胤
        </span>
        通过刑侦手法科学仪器荧光检测仪来对艺术品的成分进行分析。比如，从书画的纸张纤维可框定其年代的大致范围。又比如，对某铜炉的检测，发现其含汞量高达8%，让人不寒而栗。古玩艺术品的鉴定由科学仪器与专家眼鉴相结合，无疑是给各位藏友一份前所未有的双重保障。
      </p>
      <p class="space_content">
        本次沙龙是福建省古玩商会厦门分会换届后首场鉴宝沙龙活动，今后导报将联合福建省古玩商会厦门分会带您走进实验室，用最权威的方式，与您分享古玩艺术品的真伪之道。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/10'"><p class="ellipsis1">笔迹专家秀绝技 上电视准确“破案”</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/12'"><p class="ellipsis1">厉害了，国内首个环境损害鉴定评估标准在福建省开始实施了！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
